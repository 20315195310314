import React from 'react';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <p>&copy; 2020 POVALAB Technologies Ltd. All Rights Reserved.</p>
      </div>
    </footer>
  );
}
